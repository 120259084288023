import React from 'react';
import SvgStarHalfAltSolid from 'src/assets/svg/SvgStarHalfAltSolid.svg';
import SvgStarRegular from 'src/assets/svg/SvgStarRegular.svg';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';
import './index.scss';

export default function Rating({ className = '', value, showNmb = true, number = false }) {
    const stars = [];
    for (let i = 0; i < 5; i++) {
        const diff = value - i;
        if (diff < 0 || diff < 0.3) {
            stars.push('inactive');
        } else if (diff > 0.7) {
            stars.push('active');
        } else if (diff > 0.3 || diff < 0.7) {
            stars.push('half');
        }
    }

    return (
        <div className={'raiting ' + className}>
            <div className="stars">
                {stars.map((star, i) => {
                    if (star === 'half') {
                        return <SvgStarHalfAltSolid key={i} width={20} height={20} />;
                    } else if (star === 'active') {
                        return <SvgStarSolid key={i} width={20} height={20} />;
                    } else {
                        return <SvgStarRegular key={i} width={20} height={20} />;
                    }
                })}
            </div>
            {showNmb && value ? (
                <div className="raiting-value">{`(${number !== false ? number : value.toFixed(1)})`}</div>
            ) : null}
        </div>
    );
}
