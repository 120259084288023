import { RouteFormatter } from '../routes';

export const SEO_SUB_TYPE_PARK_LISTINGS = {
    WATER: {
        id: 'WATER',
        title: 'Dog water parks',
        route: 'water-parks',
        filter: ['Beach', 'LakePond', 'RiverStreamCreek', 'SwimmingPool'],
        region: 'WATER_PARKS',
    },
    HIKING: {
        id: 'HIKING',
        title: 'Dog hiking trails',
        route: 'hiking-trails',
        filter: 'Trail',
        region: 'HIKING_TRAILS',
    },
    FENCED: {
        id: 'FENCED',
        title: 'Fully fenced dog parks',
        route: 'fenced-parks',
        region: 'FENCED_PARKS',
    },
    FIELDS: {
        id: 'FIELDS',
        title: 'Dog fields',
        route: 'dog-parks-fields',
        filter: 'Field',
        region: 'DOG_PARKS_FIELDS',
    },
    AGILITY: {
        id: 'AGILITY',
        title: 'Dog agility parks',
        route: 'dog-agility-parks',
        filter: ['AgilityEquipment', 'ParkourObstacles'],
        region: 'DOG_AGILITY_PARKS',
    },
    SMALL: {
        id: 'SMALL',
        title: 'Small dog parks',
        route: 'small-dog-friendly',
        filter: 'SmallDog',
    },
    INDOOR: {
        id: 'INDOOR',
        title: 'Indoor Dog Parks',
        route: 'indoor-dog-parks',
        filter: 'Indoor',
        region: 'INDOOR_DOG_PARKS',
    },
    BEACH: {
        id: 'BEACH',
        title: 'Dog Beaches',
        route: 'dog-beaches',
        filter: 'Beach',
        type: 'DOG_BEACHES',
    },
};

export const SUB_TYPE_PARK = [
    {
        slug: 'dog-water-parks',
        title: 'Dog Water Parks',
        id: 'WATER',
        type: 'DOG_WATER_PARKS',
    },
    {
        slug: 'fully-fenced-dog-parks',
        title: 'Fully fenced Dog Parks',
        id: 'FENCED',
        type: 'FULLY_FENCED_DOG_PARKS',
    },
    {
        slug: 'dog-agility-parks',
        title: 'Dog Agility Parks',
        id: 'AGILITY',
        type: 'DOG_AGILITY_PARKS',
    },
    {
        slug: 'dog-fields',
        title: 'Dog Fields',
        id: 'FIELDS',
        type: 'DOG_FIELDS',
    },
    {
        slug: 'dog-hiking-trails',
        title: 'Dog Hiking Trails',
        id: 'HIKING',
        type: 'DOG_HIKING_TRAILS',
    },
    {
        slug: 'small-dog-parks',
        title: 'Small Dog Parks',
        id: 'SMALL',
        type: 'SMALL_DOG_PARKS',
    },
    {
        slug: 'indoor-dog-parks',
        title: 'Indoor Dog Parks',
        id: 'INDOOR',
        type: 'INDOOR_DOG_PARKS',
    },
    {
        slug: 'dog-beaches',
        title: 'Dog Beaches',
        id: 'BEACH',
        type: 'DOG_BEACHES',
    },
];

export const PARK_TYPES = [
    {
        text: 'Dog Water Parks',
        url: RouteFormatter.waterListings(),
    },
    {
        text: 'Dog Hiking Trails',
        url: RouteFormatter.hikingListings(),
    },
    {
        text: 'Dog Fields',
        url: RouteFormatter.fieldsListings(),
    },
    {
        text: 'Fenced Dog Parks',
        url: RouteFormatter.fencedListings(),
    },
    {
        text: 'Dog Agility Parks',
        url: RouteFormatter.agilityListings(),
    },
    {
        text: 'Small dog parks',
        url: RouteFormatter.smallDogListings(),
    },
    {
        text: 'Dog beaches',
        url: RouteFormatter.beachListings(),
    },
    {
        text: 'Indoor dog parks',
        url: RouteFormatter.indoorListings(),
    },
];

export const SUB_TYPE_PARK_LISTINGS = {
    WATER: {
        id: 'WATER',
        title: 'Dog water parks and swimming pools',
        seoTitle: 'Dog Water Parks',
        route: 'water-parks',
        filter: ['Beach', 'LakePond', 'RiverStreamCreek', 'SwimmingPool'],
        region: 'WATER_PARKS',
        seoType: 'DOG_WATER_PARKS',
        seoDesc:
            "Rent safe and private dog water parks all around the world. Sniffspot's private dog water parks are the best way to exercise your dog. We have the best variety and the best priced dog water parks anywhere!",
    },
    HIKING: {
        id: 'HIKING',
        title: 'Dog hiking trails',
        seoTitle: 'Dog Hiking Trails',
        route: 'hiking-trails',
        filter: 'Trail',
        region: 'HIKING_TRAILS',
        seoType: 'DOG_HIKING_TRAILS',
        seoDesc:
            "Rent safe and private dog hiking trails all around the world. Sniffspot's private dog hiking trails are the best way to exercise your dog. We have the best variety and the best priced dog hiking trails anywhere!",
    },
    FENCED: {
        id: 'FENCED',
        title: 'Fenced dog parks',
        seoTitle: 'Fully Fenced Dog Parks',
        route: 'fenced-parks',
        region: 'FENCED_PARKS',
        seoType: 'FULLY_FENCED_DOG_PARKS',
        seoDesc:
            "Rent safe and private fully fenced dog parks all around the world. Sniffspot's private fully fenced dog parks are the best way to exercise your dog. We have the best variety and the best priced fully fenced dog parks anywhere!",
    },
    FIELDS: {
        id: 'FIELDS',
        title: 'Dog fields',
        faqTitle: 'Frequently asked questions about renting a Sniffspot field for your dog',
        seoTitle: 'Dog Fields',
        route: 'dog-parks-fields',
        filter: 'Field',
        region: 'DOG_PARKS_FIELDS',
        seoType: 'DOG_FIELDS',
        seoDesc:
            "Rent safe and private dog fields all around the world. Sniffspot's private dog fields are the best way to exercise your dog. We have the best variety and the best priced dog fields anywhere!",
    },
    AGILITY: {
        id: 'AGILITY',
        title: 'Dog agility parks',
        seoTitle: 'Dog Agility Parks',
        route: 'dog-agility-parks',
        filter: ['AgilityEquipment', 'ParkourObstacles'],
        region: 'DOG_AGILITY_PARKS',
        seoType: 'DOG_AGILITY_PARKS',
        seoDesc:
            "Rent safe and private dog agility parks all around the world. Sniffspot's private dog agility parks are the best way to exercise your dog. We have the best variety and the best priced dog agility parks anywhere!",
    },
    SMALL: {
        id: 'SMALL',
        title: 'Small dog parks',
        seoTitle: 'Small Dog Parks',
        route: 'small-dog-friendly',
        filter: 'SmallDog',
        region: 'SMALL_DOG_FRIENDLY',
        seoType: 'SMALL_DOG_PARKS',
        seoDesc:
            "Rent safe and private small dog friendly dog parks all around the world. Sniffspot's private small dog friendly dog parks are the best way to exercise your dog. We have the best variety and the best priced small dog friendly dog parks anywhere!",
    },
    BEACH: {
        id: 'BEACH',
        title: 'Dog beaches',
        seoTitle: 'Dog Beaches',
        route: 'dog-beaches',
        filter: 'Beach',
        region: 'DOG_BEACHES',
        seoType: 'DOG_BEACHES',
        seoDesc:
            "Rent safe and private dog beaches all around the world. Sniffspot's private dog beaches are the best way to exercise your dog. We have the best variety and the best priced dog beaches anywhere!",
    },
    INDOOR: {
        id: 'INDOOR',
        title: 'Indoor dog parks',
        seoTitle: 'Indoor Dog Parks',
        route: 'indoor-dog-parks',
        filter: 'Indoor',
        region: 'INDOOR_DOG_PARKS',
        seoType: 'INDOOR_DOG_PARKS',
        seoDesc:
            "Rent safe and private indoor dog parks all around the world. Sniffspot's private indoor dog parks are the best way to exercise your dog. We have the best variety and the best priced indoor dog parks anywhere!",
    },
};
