import React from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('navigation-layout');

const NavigationLayoutSSR = ({ children, className, style }) => {
    return (
        <header className={b.mix(className)} style={style}>
            {children}
        </header>
    );
};

export default NavigationLayoutSSR;
