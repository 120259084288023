import React from 'react';
import CookieService from '../../services/cookie.service';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import { RouteFormatter } from '../../routes';
import { savePathRedirect } from '../../helpers/cookies';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import SniffButton from '../sniff-button';
import SvgSnifLogo from 'src/assets/svg/SvgSnifLogo.svg';
import './style.scss';

const MobileTopBarSignup = ({ isBarSignupTest }) => {
    if (!isBarSignupTest) {
        return '';
    }

    const onClick = () => {
        savePathRedirect();
        CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.NEWHEADER_WEB, { expires: 1 });
        window.location = RouteFormatter.signUp();
    };

    return (
        <div className="top-bar-signup">
            <a href={RouteFormatter.home()}>
                <SvgSnifLogo />
            </a>
            <SniffButton size="sm" onClick={onClick}>
                Sign up
            </SniffButton>
        </div>
    );
};

export default MobileTopBarSignup;
