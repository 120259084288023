import bemCn from 'bem-cn';
import React from 'react';
import { PARK_TYPES } from '../../consts/type-park.consts';
import { RouteFormatter } from '../../routes';
import PictureSet from '../picture-set';
import { blogs, topBlogs, topCities, topDogNames, topDogRescues, trainers } from './consts';

const b = bemCn('nav-guest-unauth');

export const RenderSubParks = () => (
    <div className={b('popup').mix('parks')}>
        <div>
            <h3 className="snif-p snif-semibold">Top cities</h3>
            <div className="flex-2-column">
                {topCities.map((v, i) => (
                    <a key={i} href={RouteFormatter.listings({ first: v.url })}>
                        {v.name}
                    </a>
                ))}
            </div>
            <a href={RouteFormatter.listings({})}>
                <span className="snif-p text-underline">More locations...</span>
            </a>
        </div>
        <div>
            <h3 className="snif-p snif-semibold">Park types</h3>
            <div className="flex-2-column parks-types">
                {PARK_TYPES.map((v, i) => (
                    <a key={i} href={v.url}>
                        {v.text}
                    </a>
                ))}
            </div>
        </div>
    </div>
);

export const RenderBlogs = () => (
    <div className={b('popup').mix('blog')}>
        <div className="pop-blog-header">
            {topBlogs.map((v, i) => (
                <a
                    key={i}
                    href={v.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`snif-p snif-medium ${i == topBlogs.length - 1 ? 'me-0' : 'me-3'}`}
                >
                    {v.title}
                </a>
            ))}
        </div>
        <h3 className="d-flex justify-content-between align-items-center">
            <span className="snif-p snif-semibold">Top articles</span>
            <a href={RouteFormatter.blog()} target="_blank" rel="noopener noreferrer">
                <span className="snif-p snif-regular text-underline">See all...</span>
            </a>
        </h3>
        <div className="flex-2-column blog-posts">
            {blogs.map((v, i) => (
                <a key={i} href={v.url} target="_blank" rel="noopener noreferrer">
                    <PictureSet imgs={v.img} width={48} height={48} alt={`${v.title} thumbnail`} />
                    <p className="snif-s1">{v.title}</p>
                </a>
            ))}
        </div>
    </div>
);

export const RenderTrainers = () => (
    <div className={b('popup').mix('trainers')}>
        <h3 className="snif-p snif-semibold">Sniffspot's annual contest for top dog trainers by city and state</h3>
        {trainers.map((v, i) => (
            <a key={i} className="d-block snif-p mt-3" href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);

export const RenderDogNames = () => (
    <div className={b('popup').mix('dog-names')}>
        <h3 className="snif-p snif-semibold">Browse our popular dog names across genders, breeds & states</h3>
        {topDogNames.map((v, i) => (
            <a key={i} className="d-block snif-p mt-3" href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);

export const RenderDogRescues = () => (
    <div className={b('popup').mix('dog-rescues')}>
        <h3 className="snif-p snif-semibold">Find top dog rescues & shelters in the United States</h3>
        {topDogRescues.map((v, i) => (
            <a key={i} className="d-block snif-p mt-3" href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);
