import React, { useEffect } from 'react';
import bemCn from 'bem-cn';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import './index.scss';

const b = bemCn('modal-mobile');

const ModalMobileSSR = ({ isOpen = false, toggle, title = '', children, className = '', resetFilter, onReset }) => {
    useEffect(() => {
        if (!isOpen) {
            const bodyEle = document.querySelector('body');
            setTimeout(() => {
                bodyEle.style.overflow = '';
            }, 120);
        }
    }, [isOpen]);

    return (
        <div className={`modal-mobile-wrapper modal-ssr ${isOpen ? '' : 'd-none'}`}>
            <div className={`${b('ssr')} ${isOpen ? 'modal-mobile-ssr__open' : 'modal-mobile-ssr__hidden'}`}>
                <div className={b('ssr-overlay')} onClick={toggle} />
                <div className={`${b()} ${className}`}>
                    <div className="modal-content">
                        <div className="modal-body">
                            {title && (
                                <div className={b('title')}>
                                    <p className="snif-s1 snif-regular mb-0">
                                        <span className="text">{title}</span>
                                        <SvgCross2 className={b('close-button')} onClick={toggle} />
                                        <span className={b('rectangle-mobile')} />
                                        {resetFilter && (
                                            <span className="snif-s2 snif-medium reset-all" onClick={onReset}>
                                                Reset all
                                            </span>
                                        )}
                                    </p>
                                </div>
                            )}
                            <div className={b('content')}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMobileSSR;
